<template>
  <div class="l-wrapper">
    <div class=" q-pa-md">
      <q-select
        outlined
        label="Year"
        :options="data.availYear"
        v-model="data.selYear"
        @update:model-value="getMonthlySales()"
      ></q-select>
    </div>
    <q-scroll-area class="l-grow bg-grey-2">
      <q-list separator>
        <q-item
          class="bg-grey-4"
          clickable
          v-for="(val, i) in data.monthly_sales"
          :key="i"
          v-ripple
        >
          <q-item-section>
            <q-item-label>{{ val.my }}</q-item-label>
            <q-item-label caption>total qty : {{ val.qty }}</q-item-label>
            <q-item-label caption>total value : {{ val.value }}</q-item-label>
          </q-item-section>

          <q-item-section side>
            <q-btn
              flat
              label="details"
              color="primary"
              :to="`/monthly-sales/detail/${val.year}/${val.month}`"
            ></q-btn>
          </q-item-section>
        </q-item>
      </q-list>
    </q-scroll-area>
    <q-btn label="New Data" color="primary" to="/monthly-sales/create"></q-btn>
  </div>
</template>
<script>
import { ref } from "vue";
import { onMounted } from "vue";
import useMonthlySales from "./useMonthlySales";
export default {
  setup() {
    const { data, getMonthlySales, getYear, $q } = useMonthlySales();

    onMounted(async () => {
      try {
        $q.loading.show({ message: "Mohon tunggu sebentar" });
        await getYear();
        await getMonthlySales();
        $q.loading.hide();
      } catch (err) {
        $q.loading.hide();
      }
    });

    return {
      data,
      getMonthlySales,
    };
  },
};
</script>
